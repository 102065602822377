import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/ecommerce.json";
import { Box, Item, Anchor } from "../../components/elements";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import APIClient from "../../APIClient";
import { TotalEnquiryContext } from "../../context/TotalEnquiryContext";
import Skeleton from "react-loading-skeleton";
import PieChartLoader from "../../components/SkeletonLoaderComponent/PieChartLoader";

export default function Ecommerce() {
  const [disable, setDisable] = useState(true);
  const {
    setProfileIdList,
    setTopHeading,
    setCounsellorId,
    setVendorId,
    setFollowUpList,
    setFollowupTerm,
  } = useContext(TotalEnquiryContext);
  const [loading, setLoading] = useState(false);
  const [enqDetails, setEnqDetails] = useState();
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [counsellor, setCounsellor] = useState("");
  const [vendor, setVendor] = useState("");
  const [newLead, setNewLead] = useState(false);
  const [all, setAll] = useState();
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [vendordata, setVendordata] = useState();
  const [counsellordata, setCounsellordata] = useState();

  // fetch vendor List
  const fetchvendordata = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.get("api/leadvendor-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((getvendorResponse) => {
        setVendordata(getvendorResponse);
      });
    });
  };

  // fetch counsellor list
  const fetchcounsellordata = async () => {
    APIClient.get("sanctum/csrf-cookie").then((response) => {
      APIClient.get("api/councellor-list", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((getcouncellorResponse) => {
        setCounsellordata(getcouncellorResponse);
      });
    });
  };

  const fetchAdmindashborddefultdata = async () => {
    setLoading(true);
    try {
      await APIClient.get("sanctum/csrf-cookie");
      const response = await APIClient.get("api/admin-dashboard-statistic", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      setLoading(false);
      setDashboardData(response.data);
      // console.log(response.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching admin dashbord data", error);
    }
  };

  // const handleFilterChange = async (data) => {
  //   // event.preventDefault();
  //   try {
  //     const response = await APIClient.post(
  //       "api/filter-admin-dashboard-statistic",
  //       {
  //         counsellor_id: data.counsellor ? data.counsellor : counsellor,
  //         vendor_id: data.vendor ?? vendor,
  //         new_lead: data.newLead ?? newLead,
  //         email: data.email ?? email,
  //         phone: data.mobile ?? mobile,
  //       },
  //       // {
  //       //   ...data,
  //       // },
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     setDashboardData(response.data);
  //     setFollowUpList(response?.data?.result[1]);
  //     console.log(dashboardData);
  //   } catch (error) {
  //     console.error("Error fetching filtered data", error);
  //   }
  // };

  // const dateWiseFilterChange = async () => {
  //   try {
  //     const response = await APIClient.post(
  //       "api/date-wise-admin-dashboard-statistic",
  //       {
  //         start_date: startDate,
  //         end_date: endDate,
  //       },
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     setDashboardData(response.data);
  //     setFollowUpList(response?.data?.result[1]);
  //     console.log(dashboardData);
  //   } catch (error) {
  //     console.error("Error fetching filtered data", error);
  //   }
  // };

  // const handleAllChange = () => {
  //   if (all) {
  //     // If "All" is checked and then unchecked, uncheck "All"
  //     setAll(false);
  //     setNewLead(true);
  //     handleFilterChange({ newLead: true });
  //   } else {
  //     // If "All" is being checked, uncheck other fields
  //     setAll(true);
  //     setNewLead(false);
  //     setCounsellor(""const [disable, setDisable] = useState(true););
  //     setVendor("");
  //     setEmail("");
  //     setMobile("");
  //     setStartDate(null);
  //     setEndDate(null);
  //     fetchAdmindashborddefultdata(); // Call full data API when "All" is selected
  //   }
  // };

  const handleNewLead = (e) => {
    setNewLead(e.target.checked);

    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      setStartDate(formattedDate);
    } else {
      setStartDate(null);
    }

    // Uncheck "All" when a date is selected
    setAll(false);
    setNewLead(false);
    setEmail("");
    setMobile("");
  };
  const handleEndDateChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      setEndDate(formattedDate);
    } else {
      setEndDate(null);
    }

    // Uncheck "All" when a date is selected
    setAll(false);
    setNewLead(false);
    setEmail("");
    setMobile("");
  };

  const handleCounsellorChange = (e) => {
    const selectedCounsellor = e.target.value;
    setCounsellor(selectedCounsellor);
    setCounsellorId(e.target.value);
  };
  const handleVendorChange = (e) => {
    const selectedVendor = e.target.value;
    setVendorId(e.target.value);
    setVendor(selectedVendor);
  };

  const handleEmailChange = (e) => {
    const selectedEmail = e.target.value;
    setEmail(selectedEmail);
  };
  const handleMobileChange = (e) => {
    const selectedMobile = e.target.value;
    setMobile(selectedMobile);
  };

  // master enquiry data
  const getEnquiryDetails = () => {
    let labels = [];
    let series = [];

    let lead_potential_name = [];
    let lead_potential_count = [];

    let followup = [];
    let followupseries = [];

    let callTypeLabel = [];
    let callTypeSeries = [];

    let callinfo = [];
    let callinfocount = [];

    let totalEnquiryStatus = 0;
    let enquiryStatusLabel = [];
    let enquiryStatusSeries = [];
    let enquiryStatusObj = [];

    let totalVendorAdmission = 0;
    let VendorAdmissionLabel = [];
    let VendorAdmissionSeries = [];
    let VendorAdmissionObj = [];

    let totalUniversityAdmission = 0;
    let UniversityAdmissionLabel = [];
    let UniversityAdmissionSeries = [];
    let UniversityAdmissionObj = [];

    let totalTransferData = 0;
    let TransferLabel = [];
    let TransferSeries = [];
    let TransferObj = [];

    let vendoradmissiondata = [];
    let vendoradmissiondatacount = [];

    let university_names = [];
    let university_counts = [];

    let totalenq = 0;
    let followupadmissiontotal = 0;
    let leadprotentialtotal = 0;
    let totalcalltype = 0;
    let callinformationtotal = 0;
    let vendoradmissionstatustotal = 0;
    let universitytotal = 0;
    let details = [];
    let potential_details = [];
    let calltype = [];
    let callinformationdetails = [];

    if (dashboardData.result && dashboardData.result.length > 0) {
      details = dashboardData.result[0].enquery_details;
      const followupDetails = dashboardData.result[1].followup_details;
      potential_details = dashboardData.result[2].lead_potential_details;
      calltype = dashboardData.result[3].call_type_Stat_details;
      callinformationdetails = dashboardData.result[4].lead_status_details;
      totalEnquiryStatus = dashboardData.result[5].total_enquiry_status;
      enquiryStatusObj = dashboardData.result[5].enquiry_status_details;
      enquiryStatusLabel = enquiryStatusObj.map(
        (item) => item.enquiry_status_name
      );
      enquiryStatusSeries = enquiryStatusObj.map(
        (item) => item.enquiry_status_count
      );

      VendorAdmissionObj = dashboardData.result[6].vendor_admission_details;

      VendorAdmissionLabel = VendorAdmissionObj.map((item) => item.vendor_name);
      VendorAdmissionSeries = VendorAdmissionObj.map(
        (item) => item.admission_done_count
      );
      totalVendorAdmission = dashboardData.result[6].total_vendor_admission;

      UniversityAdmissionObj =
        dashboardData.result[7].univercity_admission_status_details;
      UniversityAdmissionLabel = UniversityAdmissionObj.map(
        (item) => item.univercity_name
      );
      UniversityAdmissionSeries = UniversityAdmissionObj.map(
        (item) => item.univercity_count
      );
      totalUniversityAdmission =
        dashboardData.result[7].total_univercity_admission;

      const universitydata =
        dashboardData.result[7]?.univercity_admission_status_details;

      labels = details.map((detail) => `${detail.lead_status_name}`);
      series = details.map((detail) => detail.lead_status_count);

      lead_potential_name = potential_details.map(
        (potential_details) => `${potential_details.lead_potential_name}`
      );
      lead_potential_count = potential_details.map(
        (potential_details) => potential_details.lead_potential_count
      );

      callinfo = callinformationdetails.map(
        (callinformationdetails) =>
          `${callinformationdetails.call_information_name}`
      );
      callinfocount = callinformationdetails.map(
        (callinformationdetails) =>
          callinformationdetails.call_information_count
      );

      followup = Object.keys(followupDetails)
        .map((key) => `${key}`)
        .filter((_, index) => [0, 2, 4].includes(index));
      followupseries = Object.values(followupDetails).filter((_, index) =>
        [0, 2, 4].includes(index)
      );

      if (calltype) {
        callTypeLabel = calltype.map((item) => item.calltype_name);
        callTypeSeries = calltype.map((item) => item.call_type_count);
      }

      if (universitydata) {
        university_names = universitydata.map(
          (universitydata) => `${universitydata.univercity_name}`
        );
        university_counts = universitydata.map(
          (universitydata) => universitydata.univercity_count
        );
      }

      TransferObj = dashboardData.result[8].counsellor_transfer_details;
      TransferLabel = TransferObj.map((item) => item.counsellor_name);
      TransferSeries = TransferObj.map((item) => item.transfer_count);
      totalTransferData = dashboardData.result[8].total_tranfer_count;

      totalenq = dashboardData.result[0].total_enquiry;
      followupadmissiontotal = dashboardData.result[1].total_follow_up;
      leadprotentialtotal = dashboardData.result[2].total_lead_potential;
      totalcalltype = dashboardData.result[3].total_call_type;
      callinformationtotal = dashboardData.result[4].total_call_information;
      vendoradmissionstatustotal =
        dashboardData.result[6].total_vendor_admission;
      // universitytotal = dashboardData.result[7].total_univercity_admission;
    }
    return {
      labels,
      details,
      potential_details,
      calltype,
      series,
      followup,
      followupseries,
      lead_potential_name,
      lead_potential_count,
      callTypeSeries,
      callTypeLabel,
      callinfo,
      callinfocount,
      vendoradmissiondata,
      vendoradmissiondatacount,
      university_names,
      university_counts,
      followupadmissiontotal,
      leadprotentialtotal,
      totalcalltype,
      callinformationtotal,
      vendoradmissionstatustotal,
      universitytotal,
      totalenq,
      callinformationdetails,
      enquiryStatusObj,
      enquiryStatusLabel,
      enquiryStatusSeries,
      totalEnquiryStatus,
      totalVendorAdmission,
      VendorAdmissionLabel,
      VendorAdmissionSeries,
      VendorAdmissionObj,

      totalUniversityAdmission,
      UniversityAdmissionLabel,
      UniversityAdmissionSeries,
      UniversityAdmissionObj,

      totalTransferData,
      TransferLabel,
      TransferSeries,
      TransferObj,
    };
  };
  const {
    labels,
    details,
    calltype,
    potential_details,
    followup,
    series,
    followupseries,
    lead_potential_name,
    lead_potential_count,
    callTypeLabel,
    callTypeSeries,
    callinfo,
    callinfocount,
    followupadmissiontotal,
    leadprotentialtotal,
    totalcalltype,
    callinformationtotal,
    callinformationdetails,
    totalenq,
    enquiryStatusObj,
    enquiryStatusLabel,
    enquiryStatusSeries,
    totalEnquiryStatus,
    totalVendorAdmission,
    VendorAdmissionLabel,
    VendorAdmissionSeries,
    VendorAdmissionObj,
    totalUniversityAdmission,
    UniversityAdmissionLabel,
    UniversityAdmissionSeries,
    UniversityAdmissionObj,
    totalTransferData,
    TransferLabel,
    TransferSeries,
    TransferObj,
  } = getEnquiryDetails();

  //1
  const totalenquiry = {
    series: series,
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const clickedLabel = config.w.config.labels[config.dataPointIndex];
            handleClick(clickedLabel);
          },
        },
        width: "100%",
        type: "pie",
      },
      labels: labels,
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -25,
          },
        },
      },
      title: {
        text: ` Lead status (${totalenq})`,
      },
      title2: {
        text: `Lead status`,
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      legend: {
        show: false,
      },
    },
  };
  // const handleDataPointClick = (index) => {
  //   const selectedItem = dashboardData?.result[0]?.enquery_details[index];
  //   navigate(`/dasbordlead-status/${selectedItem.id}`);
  // };
  const handleClick = (label) => {
    const clickedData =
      details && details.find((item) => item.lead_status_name === label);
    setTopHeading(label);
    setProfileIdList(clickedData.profile_ids);
    navigate("/view-profiles");
    // You can perform further actions with clickedData.profile_ids here
  };

  // need fix 2
  const followupadmisation = {
    series: followupseries,
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const label = config.w.config.labels[config.dataPointIndex];
            if (label === "follow_up") {
              setTopHeading("Follow Up");
              setProfileIdList(
                dashboardData &&
                  dashboardData.result[1].followup_details
                    ?.followup_call_status_ids &&
                  dashboardData.result[1].followup_details
                    ?.followup_call_status_ids
              );
              navigate("/view-profiles");
            } else if (label === "failed_follow_up") {
              setTopHeading("Failed Follow Up");
              setProfileIdList(
                dashboardData &&
                  dashboardData.result[1].followup_details
                    ?.missfollowup_call_status_ids &&
                  dashboardData.result[1].followup_details
                    ?.missfollowup_call_status_ids
              );
              navigate("/view-profiles");
            }
          },
        },
        width: "100%",
        type: "pie",
      },
      labels: followup,
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -35,
          },
        },
      },
      title: {
        text: `Follow up  (${followupadmissiontotal})`,
      },
      title2: {
        text: `Follow up`,
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      legend: {
        show: false,
      },
    },
  };

  //3
  const leadprotential = {
    series: lead_potential_count,
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const clickedLabel = config.w.config.labels[config.dataPointIndex];
            handlePotentialClick(clickedLabel);
          },
        },
        width: "100%",
        type: "pie",
      },
      labels: lead_potential_name,
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -35,
          },
        },
      },
      title: {
        text: `Lead Protential (${leadprotentialtotal})`,
      },
      title2: {
        text: `Lead Protential`,
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      legend: {
        show: false,
      },
    },
  };
  const handlePotentialClick = (label) => {
    const clickedData =
      potential_details &&
      potential_details.find((item) => item.lead_potential_name === label);
    setTopHeading(`Lead Potential (${label})`);
    setProfileIdList(clickedData.profile_ids);
    navigate("/view-profiles");
  };

  //4
  const calltypeChart = {
    series: callTypeSeries,
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const clickedLabel = config.w.config.labels[config.dataPointIndex];
            handleCallTypeClick(clickedLabel);
          },
        },
        width: "100%",
        type: "pie",
      },
      labels: callTypeLabel,
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -25,
          },
        },
      },
      title: {
        text: `Call Type (${totalcalltype})`,
      },
      title2: {
        text: `Call Type`,
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      legend: {
        show: false,
      },
    },
  };
  const handleCallTypeClick = (label) => {
    const clickedData =
      calltype && calltype.find((item) => item.calltype_name === label);
    setTopHeading(`Call Type (${label})`);
    setProfileIdList(clickedData.profile_ids);
    navigate("/view-profiles");
  };

  const callinformation = {
    series: callinfocount,
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const clickedLabel = config.w.config.labels[config.dataPointIndex];
            handleCallInformationClick(clickedLabel);
          },
        },
        width: "100%",
        type: "pie",
      },
      labels: callinfo,
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -10,
          },
        },
      },
      title: {
        text: `Call Information (${callinformationtotal})`,
      },
      title2: {
        text: `Call Information`,
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      legend: {
        show: false,
      },
    },
  };
  const handleCallInformationClick = (label) => {
    const clickedData =
      callinformationdetails &&
      callinformationdetails.find(
        (item) => item.call_information_name === label
      );
    setTopHeading(`Call Information (${label})`);
    setProfileIdList(clickedData.profile_ids);
    navigate("/view-profiles");
  };

  const enquiryStatus = {
    series: enquiryStatusSeries,
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const clickedLabel = config.w.config.labels[config.dataPointIndex];
            handleEnquiryStatusClick(clickedLabel);
          },
        },
        width: "100%",
        type: "pie",
      },
      labels: enquiryStatusLabel,
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -25,
          },
        },
      },
      title: {
        text: ` Enquiry status (${totalEnquiryStatus})`,
      },
      title2: {
        text: ` Enquiry status`,
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      legend: {
        show: false,
      },
    },
  };
  const handleEnquiryStatusClick = (label) => {
    const clickedData =
      enquiryStatusObj &&
      enquiryStatusObj.find((item) => item.enquiry_status_name === label);
    setTopHeading(`Enquiry Status (${label})`);
    setProfileIdList(clickedData.profile_ids);
    navigate("/view-profiles");
  };

  const venderaplicationstatus = {
    series: VendorAdmissionSeries,
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const clickedLabel = config.w.config.labels[config.dataPointIndex];
            handleVendorClick(clickedLabel);
          },
        },
        width: "100%",
        type: "pie",
      },
      labels: VendorAdmissionLabel,
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -35,
          },
        },
      },
      title: {
        text: `Vendor Admission Status(${totalVendorAdmission})`,
      },
      title2: {
        text: `Vendor Admission Status`,
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      legend: {
        show: false,
      },
    },
  };
  const handleVendorClick = (label) => {
    const clickedData =
      VendorAdmissionObj &&
      VendorAdmissionObj.find((item) => item.vendor_name === label);
    setTopHeading(`Vendor Admission Status For (${label})`);
    setProfileIdList(clickedData.profile_ids);
    navigate("/view-profiles");
  };
  const autouniversityadmission = {
    series: UniversityAdmissionSeries,
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const clickedLabel = config.w.config.labels[config.dataPointIndex];
            handleUniversityClick(clickedLabel);
          },
        },
        width: "100%",
        type: "pie",
      },
      labels: UniversityAdmissionLabel,
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -35,
          },
        },
      },
      title: {
        text: `Auto / University Admission (${totalUniversityAdmission})`,
      },
      title2: {
        text: `Auto / University Admission`,
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      legend: {
        show: false,
      },
    },
  };
  const handleUniversityClick = (label) => {
    const clickedData =
      UniversityAdmissionObj &&
      UniversityAdmissionObj.find((item) => item.univercity_name === label);
    setTopHeading(`University Admission Status For (${label})`);
    setProfileIdList(clickedData.profile_ids);
    navigate("/view-profiles");
  };
  const transfereddata = {
    series: TransferSeries,
    options: {
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const clickedLabel = config.w.config.labels[config.dataPointIndex];
            handleTransferClick(clickedLabel);
          },
        },
        width: "100%",
        type: "pie",
      },
      labels: TransferLabel,
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -35,
          },
        },
      },
      title: {
        text: `Transfered Data(${totalTransferData})`,
      },
      title2: {
        text: `Transfered Data`,
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name];
        },
      },
      legend: {
        show: false,
      },
    },
  };
  const handleTransferClick = (label) => {
    const clickedData =
      TransferObj && TransferObj.find((item) => item.counsellor_name === label);
    setTopHeading(`Transfer Data For (${label})`);
    setProfileIdList(clickedData.profile_ids);
    navigate("/view-profiles");
  };
  useEffect(() => {
    if (
      counsellor === "" &&
      vendor === "" &&
      (newLead === "" || newLead === undefined || newLead === false) &&
      // (all === "" || all === undefined) &&
      (startDate === "" || startDate === null) &&
      (endDate === "" || endDate === null) &&
      email === "" &&
      mobile === ""
    ) {
      fetchAdmindashborddefultdata();
      fetchvendordata();
      fetchcounsellordata();
      setDisable(true);
      console.log("truedisable", disable);
    } else {
      setDisable(false);
      console.log("falsedisable", disable);
    }
  }, [counsellor, vendor, newLead, all, startDate, endDate, email, mobile]);

  useEffect(() => {
    if (
      !localStorage.getItem("role_type") ||
      localStorage.getItem("role_type") != 1
    ) {
      navigate("/login");
    }
  }, []);

  async function handleSearch() {
    if (email) {
      try {
        const response = await APIClient.post(
          "api/filter-admin-dashboard-statistic",
          {
            // counsellor_id: counsellor,
            // vendor_id: vendor,
            // new_lead: newLead,
            email: email,
            // phone: mobile,
          },

          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setDashboardData(response.data);
        setFollowUpList(response?.data?.result[1]);
        console.log(dashboardData);
      } catch (error) {
        console.error("Error fetching filtered data", error);
      }
    } else if (mobile) {
      try {
        const response = await APIClient.post(
          "api/filter-admin-dashboard-statistic",
          {
            // counsellor_id: counsellor,
            // vendor_id: vendor,
            // new_lead: newLead,
            // email: email,
            phone: mobile,
          },

          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setDashboardData(response.data);
        setFollowUpList(response?.data?.result[1]);
        console.log(dashboardData);
      } catch (error) {
        console.error("Error fetching filtered data", error);
      }
    } else {
      try {
        const response = await APIClient.post(
          "api/filter-admin-dashboard-statistic",
          {
            counsellor_id: counsellor,
            vendor_id: vendor,
            new_lead: newLead === true || newLead === "true" ? "true" : "",
            start_date: startDate,
            end_date: endDate,
          },

          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        setDashboardData(response.data);
        setFollowUpList(response?.data?.result[1]);
        console.log("newled", newLead);
        console.log(dashboardData);
      } catch (error) {
        console.error("Error fetching filtered data", error);
      }
    }
  }

  return (
    <>
      <>
        <PageLayout>
          <Row>
            <Col xl={12}>
              {loading === true ? (
                <Skeleton height={70} width={"100%"} />
              ) : (
                <Box className="mc-card">
                  <Breadcrumb title={data?.pageTitle}>
                    {data?.breadcrumb?.map((item, index) => (
                      <Item key={index} className="mc-breadcrumb-item">
                        {item.path ? (
                          <Anchor
                            className="mc-breadcrumb-link"
                            href={item.path}
                          >
                            {item.text}
                          </Anchor>
                        ) : (
                          item.text
                        )}
                      </Item>
                    ))}
                  </Breadcrumb>
                </Box>
              )}
            </Col>
            <Col xl={12} className="text-center">
              {loading === true ? (
                <Skeleton height={50} width={"100%"} count={3} />
              ) : (
                <>
                  <Form>
                    {/* <Form> */}
                    <Form.Group as={Row}>
                      <Form.Label
                        column
                        sm="1"
                        className="d-flex align-items-center"
                      >
                        Counsellor
                      </Form.Label>
                      <Col sm="2" style={{ width: "22%" }}>
                        <Form.Select
                          aria-label="Default select example"
                          style={{ fontSize: "11px" }}
                          value={counsellor}
                          onChange={(e) => handleCounsellorChange(e)}
                        >
                          <option value="">
                            {"<-- Select Counsellor -->"}
                          </option>
                          {counsellordata &&
                            counsellordata.data.councellor_list.map(
                              (item, index) => (
                                <option key={item.id} value={item.id}>
                                  {item.councellor_name}
                                </option>
                              )
                            )}
                        </Form.Select>
                      </Col>
                      <Form.Label
                        column
                        sm="1"
                        className="d-flex align-items-center"
                      >
                        Lead Vendor
                      </Form.Label>
                      <Col sm="2" style={{ width: "20%" }}>
                        <Form.Select
                          aria-label="Default select example"
                          style={{ fontSize: "11px" }}
                          value={vendor}
                          onChange={(e) => {
                            handleVendorChange(e);
                          }}
                        >
                          <option value="">{"<-- Select Vendor -->"}</option>
                          {vendordata &&
                            vendordata.data.leadvendor_list.map(
                              (item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              )
                            )}
                        </Form.Select>
                      </Col>
                      <Col
                        sm="2"
                        className="text-start d-flex align-items-center"
                        id="form-label"
                      >
                        <Form.Check
                          inline
                          label="New Led"
                          name="new Lead"
                          type="checkbox"
                          className="form-label"
                          checked={newLead}
                          onChange={handleNewLead}
                        />
                        {/* <Form.Check
                        inline
                        label="All"
                        name="All"
                        type="checkbox"
                        className="form-label"
                        checked={all}
                        onChange={handleAllChange}
                      /> */}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mt-2">
                      <Form.Label
                        column
                        sm="1"
                        className="d-flex align-items-center"
                        style={{ marginLeft: "-4px" }}
                      >
                        From {"-->"}
                      </Form.Label>
                      <Col sm="2" className="d-flex">
                        <DatePicker
                          className="form-control"
                          placeholderText="Select Date"
                          selected={startDate ? new Date(startDate) : null}
                          onChange={handleStartDateChange}
                          dateFormat="dd-MM-yyyy"
                        />
                        {startDate && (
                          <button
                            className="btn btn-danger"
                            style={{ marginLeft: "16px" }}
                            type="button"
                            onClick={() => setStartDate(null)}
                          >
                            Clear
                          </button>
                        )}
                      </Col>
                      <Form.Label
                        column
                        sm="1"
                        className="text-start px-4 d-flex align-items-center"
                      >
                        to {"-->"}
                      </Form.Label>
                      <Col sm="2" className="d-flex">
                        <DatePicker
                          className="form-control"
                          placeholderText="Select Date"
                          selected={endDate ? new Date(endDate) : null}
                          onChange={handleEndDateChange}
                          dateFormat="dd-MM-yyyy"
                        />
                        {endDate && (
                          <button
                            className="btn btn-danger"
                            style={{ marginLeft: "16px" }}
                            type="button"
                            onClick={() => setEndDate(null)}
                          >
                            Clear
                          </button>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-2">
                      <Form.Label
                        column
                        sm="1"
                        className="d-flex align-items-center"
                      >
                        Email
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={handleEmailChange}
                        />
                      </Col>

                      <Form.Label
                        column
                        sm="1"
                        className=" text-center d-flex align-items-center"
                      >
                        Mobile
                      </Form.Label>
                      <Col sm="3">
                        <Form.Control
                          required
                          type="number"
                          placeholder="Enter Number"
                          value={mobile}
                          onChange={handleMobileChange}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                  <button
                    id="searchbtn"
                    disabled={disable}
                    style={{ marginTop: "20px" }}
                    className="btn btn-primary"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </>
              )}

              <Row className="mt-4" style={{ overflow: "hidden" }}>
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PieChartLoader load={loading}>
                    {
                      <h6 className="noDataTitle">
                        {series.every((item) => item === 0) &&
                          totalenquiry.options.title2.text}
                      </h6>
                    }
                    {series.every((item) => item === 0) ? (
                      <div className="NoDataText">
                        <h4>No Data</h4>
                      </div>
                    ) : (
                      <ReactApexChart
                        width={390}
                        options={totalenquiry.options}
                        series={totalenquiry.series}
                        type="pie"
                      />
                    )}
                  </PieChartLoader>
                </Col>
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PieChartLoader load={loading}>
                    {
                      <h6 className="noDataTitle">
                        {followupseries.every((item) => item === 0) &&
                          followupadmisation.options.title2.text}
                      </h6>
                    }
                    {followupseries.every((item) => item === 0) ? (
                      <div className="NoDataText">
                        <h4>No Data</h4>
                      </div>
                    ) : (
                      <ReactApexChart
                        width={390}
                        options={followupadmisation.options}
                        series={followupadmisation.series}
                        type="pie"
                      />
                    )}
                  </PieChartLoader>
                </Col>
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PieChartLoader load={loading}>
                    {
                      <h6 className="noDataTitle">
                        {lead_potential_count.every((item) => item === 0) &&
                          leadprotential.options.title2.text}
                      </h6>
                    }
                    {lead_potential_count.every((item) => item === 0) ? (
                      <div className="NoDataText">
                        <h4>No Data</h4>
                      </div>
                    ) : (
                      <ReactApexChart
                        width={390}
                        options={leadprotential.options}
                        series={leadprotential.series}
                        type="pie"
                      />
                    )}
                  </PieChartLoader>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PieChartLoader load={loading}>
                    {
                      <h6 className="noDataTitle">
                        {callTypeSeries.every((item) => item === 0) &&
                          calltypeChart.options.title2.text}
                      </h6>
                    }
                    {callTypeSeries.every((item) => item === 0) ? (
                      <div className="NoDataText">
                        <h4>No Data</h4>
                      </div>
                    ) : (
                      <ReactApexChart
                        width={390}
                        options={calltypeChart.options}
                        series={calltypeChart.series}
                        type="pie"
                      />
                    )}
                  </PieChartLoader>
                </Col>
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PieChartLoader load={loading}>
                    {
                      <h6 className="noDataTitle">
                        {callinfocount.every((item) => item === 0) &&
                          callinformation.options.title2.text}
                      </h6>
                    }
                    {callinfocount.every((item) => item === 0) ? (
                      <div className="NoDataText">
                        <h4>No Data</h4>
                      </div>
                    ) : (
                      <ReactApexChart
                        width={390}
                        options={callinformation.options}
                        series={callinformation.series}
                        type="pie"
                      />
                    )}
                  </PieChartLoader>
                </Col>
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PieChartLoader load={loading}>
                    {
                      <h6 className="noDataTitle">
                        {enquiryStatusSeries.every((item) => item === 0) &&
                          enquiryStatus.options.title2.text}
                      </h6>
                    }
                    {enquiryStatusSeries.every((item) => item === 0) ? (
                      <div className="NoDataText">
                        <h4>No Data</h4>
                      </div>
                    ) : (
                      <ReactApexChart
                        width={390}
                        options={enquiryStatus.options}
                        series={enquiryStatus.series}
                        type="pie"
                      />
                    )}
                  </PieChartLoader>
                </Col>
              </Row>

              <Row className="mt-4 mb-4">
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PieChartLoader load={loading}>
                    {
                      <h6 className="noDataTitle">
                        {VendorAdmissionSeries.every((item) => item === 0) &&
                          venderaplicationstatus.options.title2.text}
                      </h6>
                    }
                    {VendorAdmissionSeries.every((item) => item === 0) ? (
                      <div className="NoDataText">
                        <h4>No Data</h4>
                      </div>
                    ) : (
                      <ReactApexChart
                        width={390}
                        options={venderaplicationstatus.options}
                        series={venderaplicationstatus.series}
                        type="pie"
                      />
                    )}
                  </PieChartLoader>
                </Col>
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PieChartLoader load={loading}>
                    {
                      <h6 className="noDataTitle">
                        {UniversityAdmissionSeries.every(
                          (item) => item === 0
                        ) && autouniversityadmission.options.title2.text}
                      </h6>
                    }
                    {UniversityAdmissionSeries.every((item) => item === 0) ? (
                      <div className="NoDataText">
                        <h4>No Data</h4>
                      </div>
                    ) : (
                      <ReactApexChart
                        width={390}
                        options={autouniversityadmission.options}
                        series={autouniversityadmission.series}
                        type="pie"
                      />
                    )}
                  </PieChartLoader>
                </Col>
                <Col
                  xl={4}
                  className="d-flex justify-content-center align-items-center"
                >
                  <PieChartLoader load={loading}>
                    {
                      <h6 className="noDataTitle">
                        {TransferSeries.every((item) => item === 0) &&
                          transfereddata.options.title2.text}
                      </h6>
                    }
                    {TransferSeries.every((item) => item === 0) ? (
                      <div className="NoDataText">
                        <h4>No Data</h4>
                      </div>
                    ) : (
                      <ReactApexChart
                        width={390}
                        options={transfereddata.options}
                        series={transfereddata.series}
                        type="pie"
                      />
                    )}
                  </PieChartLoader>
                </Col>
              </Row>
            </Col>
          </Row>
        </PageLayout>
      </>
    </>
  );
}
